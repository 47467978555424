import table from "../../ASSETS/Icone/table.png"
import reserved from '../../ASSETS/Icone/reservation.png'
import Loading from '../../COMPONENTS/Loading';
import React, { useState, useEffect } from 'react';
import  Header  from "../../COMPONENTS/Header";
import { MdOutlineAccessTime } from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { FaBan, FaPlay, FaStop, FaUser } from "react-icons/fa6";
import { store} from "../../API/Reservation/store";
//import { toast } from "react-toastify";
import { ToastContainer, toast } from 'react-toastify';
import { logo } from "../../API/Logo/logo";
import { customer } from "../../API/Customer/customer";
import { add } from "../../API/Reservation/add";
import { useCustomEventListener } from 'react-custom-events';
import { all } from "../../API/Reservation/all";
import { update } from "../../API/Reservation/update";
import { Link } from "react-router-dom";
import axios from "axios";
import { disponibles } from "../../API/Reservation/disponibles";
import { FaShoppingCart } from "react-icons/fa";

function Reservation() {
  const [loading, setLoading] = useState(true);

      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);
      //  let navigate = useNavigate();
      // const logout = ()=>{
      //  accountService.logout()
      //   navigate('/')
      // }

  //const [reservate, setReservate] = useState([]);
  const [tables, setTables] = useState(0);
  const [ta, setTa] = useState(0);
  const [etatForm,setEtatForm] = useState(true);
  const [showInput,setShowInput] = useState(false);
  const [filteredStarting,setFilteredStarting] = useState([]);
  const [search,setSearch] = useState('');
  const [entre, setEntre] = useState(false);
  const [part,setPart] = useState(true);
  const [startDate,setStartDate] = useState(new Date().toISOString().split("T")[0]);
  const [startTime,setStartTime] = useState('');
  const [allReservations, setAllReservations] = useState([]);
  const [openAttent,setOpenAttente] = useState(false)
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [bgColord, setBgColord] = useState('bg-[#C2C2C2]');
  const [bgColorr, setBgColorr] = useState('bg-[#209313]');
  const [bgColora, setBgColora] = useState('bg-[#C2C2C2]');
  const [bgColoro, setBgColoro] = useState('bg-[#C2C2C2]');
  const [tabler,setTabler] = useState(true);
  const [dispo,setDispo]=useState(false);
  const [occupe,setOccupe] = useState(false);
  const [attent,setAttent] = useState(false);
  const [attente,setAttente]  = useState(0);
  const [attentes,setAttentes]  = useState([]);
  const [occup,setOccup] = useState(0);
  const [occupes,setOccupes] = useState([]);
  const [dispos,setDispos] = useState([]);
  const [azForm,setAzForm] = useState(false)
  const [azCustomers,setAzCustomers] = useState([]);
  const [isChecked,setIsChecked] = useState(false)

  const onTablerClick = ()=>{
     setBgColorr('bg-[#209313]')
     setBgColord('bg-[#C2C2C2]')
     setBgColora('bg-[#C2C2C2]')
     setBgColoro('bg-[#C2C2C2]')
     setTabler(true)
     setDispo(false)
     setOccupe(false)
     setAttent(false)
  }
  const onDispoClick = ()=>{
     setBgColord('bg-[#209313]')
     setBgColorr('bg-[#C2C2C2]')
     setBgColora('bg-[#C2C2C2]')
     setBgColora('bg-[#C2C2C2]')
     setTabler(false)
     setDispo(true)
     setOccupe(false)
     setAttent(false)
  }

  const onAttentClick = ()=>{
     setBgColora('bg-[#209313]')
     setBgColorr('bg-[#C2C2C2]')
     setBgColord('bg-[#C2C2C2]')
     setBgColoro('bg-[#C2C2C2]')
     setTabler(false)
     setDispo(false)
     setOccupe(false)
     setAttent(true)
  }

  const onOccupClick = ()=>{
     setBgColoro('bg-[#209313]')
     setBgColorr('bg-[#C2C2C2]')
     setBgColora('bg-[#C2C2C2]')
     setBgColord('bg-[#C2C2C2]')
     setTabler(false)
     setDispo(false)
     setOccupe(true)
     setAttent(false)
  }

  const id = JSON.parse(localStorage.getItem('id'))
  const [formDatas,setFormDatas] = useState({
          person: '1',
          datedeb: "",
          heuredeb: "",
          message: "",
          noms: "",
          comptes: "",
          token: id,
          persons: ""
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDatas((prevFormDatas) => ({
      ...prevFormDatas,
      [name]: value || '',
    }));
    if (name === 'person' && value==='plus') {
      setShowInput(true);
    }else if(name === 'person' && value !== 'plus'){
      setShowInput(false)
    }
    if(name==='compte'){
      if(value === 'webcompany')
      {
        setEntre(true);
        setPart(false);
      }
      else if (value === 'webbyyer'){
        setEntre(false);
        setPart(true);
      }
    }
  };
  const handleInputPersonChange = (e) => {
    const { name,value } = e.target;
    setFormDatas((prevFormDatas) => ({
      ...prevFormDatas,
        [name]: value,
    }));
  };

  const handlEntreChange = (e)=>{
    const {value} = e.target;
    setFormDatas((prevFormDatas)=>({
      ...prevFormDatas,
        compte:value
    }));
    setEntre(true);
    setPart(false);
 }
 const handlePartChange = (e)=>{
  const {name,value} = e.target;
  setFormDatas((prevFormDatas)=>({
    ...prevFormDatas,
    [name]:value
  }))
  setPart(true);
  setEntre(false)
 }

const [errors,setErrors] = useState([])

const handlerSubmit = async (e) => {
  e.preventDefault();
  try {
   const data = await store(formDatas);
   if(data.status === 422)
   {
        setErrors(data.errors) 
   }
    if (data.newReservation) {
      toast.success("Réservation effectuée avec succès");
      setDispos(data.seats)
      // Mettez à jour toutes les réservations
      setAllReservations((prevReservations) => {
        // Si la réservation existe déjà, on la met à jour, sinon on l'ajoute
        const alreadyExists = prevReservations.some(
          (res) => res.reservation_id === data.newReservation.reservation_id
        );

        const updatedReservations = alreadyExists
          ? prevReservations.map((res) =>
              res.reservation_id === data.newReservation.reservation_id
                ? { ...res, ...data.newReservation }
                : res
            )
          : [...prevReservations, data.newReservation];

        // Process reservations to split date and time
        const processedReservations = updatedReservations.map((reserve) => {
          if (reserve.starting_date) {
            const [date, time] = reserve.starting_date.split(" ");
            return {
              ...reserve,
              starting_date: date,
              starting_time: time || reserve.starting_time,
              status: reserve.status,
            };
          }
          return { ...reserve };
        });

        // Mettre à jour les tables et les données filtrées
        const newCurrentDate = new Date().toISOString().split("T")[0];
        const filteredReservations = processedReservations.filter(
          (res) => res.starting_date === newCurrentDate
        );

        const filteredNewAttente = updatedReservations.filter(res=>res.status === 1)
        setAttente(filteredNewAttente.length)
        setAttentes(filteredNewAttente)

        setFilteredStarting(filteredReservations);
        setTa(data.tab);
        setTables(data.tables);

        return processedReservations;
      });
    }
    if(ta === 0){
       return toast.error(data.errors)
    }

    setFormDatas({
      person: "1",
      datedeb: "",
      heuredeb: "",
      message: "",
      comptes: "",
      persons: "",
      noms: "",
      token: id,
    });

  //  setErrors({});
  } catch (error) {
    console.log(error);
  }
};


 const handleReservationChange = (event)=>{
    const seachValue = event.target.value.toLowerCase();
    setSearch(seachValue)
 }
 const disponible = async()=>{
  await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getAllTableDisponible`)
              .then((res)=>{
                  setTables(res.data.table )
                  setTa(res.data.nbre)
              }).catch(error=>{
                console.error("Erreur ",error)
              })
 }

 useEffect(()=>{
    disponible()
 },[])

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await all();
      if (response.reservaty) {
        const ReservationWithTime = response.reservaty.map((reserve) => {
          const [date, time] = reserve.starting_date.split(' ');
          return { ...reserve, starting_date: date, starting_time: time };
        });
        const currentDate = new Date().toISOString().split('T')[0]; // Date courante au format YYYY-MM-DD
        // Filtrer par date courante
        const filteredByCurrentDate = ReservationWithTime.filter(reserve => reserve.starting_date === currentDate);
        const filteredAttente = response.reservaty.filter(res=>res.status === 1)
        const filteredOccuper = response.reservaty.filter(res=>res.status === 2)
        setAttente(filteredAttente.length)
        setAttentes(filteredAttente)
        setOccup(filteredOccuper.length)
        setOccupes(filteredOccuper)
        setAllReservations(ReservationWithTime);
       // setReservate(filteredByCurrentDate); // Mettez à jour l'état avec les données filtrées
        setFilteredStarting(filteredByCurrentDate); // Initialiser avec les données filtrées par la date courante
        setTables(response.tables); // Mettez à jour l'état avec les données récupérées
        setTa(response.tab);
        // if (dispos.length === 0) {
        //   setEtatForm(false);
        // }
      } 
    } catch (error) {
      console.log("Erreur lors de la récupération :", error);
    }
  };

  fetchData();
}, []);

useEffect(() => {
  if (allReservations) {
    if (search || startDate || startTime) { // Vérifier s'il y a une recherche en cours
      const filtered = allReservations.filter((reserve) => {
        const matchDate = startDate === '' || (reserve.starting_date && reserve.starting_date.includes(startDate));
        const matchTime = startTime === '' || (reserve.starting_time && reserve.starting_time.includes(startTime));  
        let matchSearch;
        if (search && isNaN(search)) {
          matchSearch = search === '' || 
                        (reserve.LastName && reserve.LastName.toLowerCase().includes(search.toLowerCase())) || 
                        (reserve.Names && reserve.Names.toLowerCase().includes(search.toLowerCase()));
        } else {
          matchSearch = (reserve.seat_id && reserve.seat_id.toString().includes(search)) || 
                        (reserve.starting_time && reserve.starting_time.includes(search));
        }

        return matchDate && matchTime && matchSearch;
      });
      const filteredByAttente = filtered.filter(res=>res.status === 1);
      const filteredByOccuper = filtered.filter(res=>res.status === 2);
      setAttentes(filteredByAttente)
      setAttente(filteredByAttente.length)
      setOccupes(filteredByOccuper)
      setOccup(filteredByOccuper.length)
      setFilteredStarting(filtered);
      setTables(filtered.length)
    }
  }
}, [allReservations, search, startDate, startTime]);

useEffect(()=>{
  disponibles().then((response)=>{
      setDispos(response.seats)
   }).catch((error)=>{
    console.log("erreur lors ",error)
   })
},[])


 
  const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])
      const [isModalOpen,setIsModalOpen] = useState(false);
      const handeClickP = ()=>{
        setIsModalOpen(true);
        setAzForm(false)
        setEtatForm(true)
      }
      const handleCloseModal = ()=>{
        setIsModalOpen(false)
        setEtatForm(true)
        setAzForm(false)
      }
    const [custormer,setCustormer] = useState([]);
    useEffect(()=>{
        customer().then(response=>{
          setCustormer(response.data)
      }).catch((error)=>{
        console.log("Erreur ",error)
      })
    },[])
    const [formModal,setFormModal] = useState({
      nom: "",
      prenom: "",
      mail: "",
      tel: "",
      compte:""
    });
    const handelFormModalChange = (e)=>{
          const {name,value} = e.target;
          setFormModal((prevFormModalData)=>({
             ...prevFormModalData,
             [name]:value || ''
          }))
    }
    useCustomEventListener('customerAdded', (data) => {
      setCustormer((cust)=>[...cust, data]);
    })
    const [errorModal,setErrorModal]  = useState({});
    const handleModalFormSubmit = async (e) => {
      e.preventDefault();
      try {
          await add(formModal);
         
          setFormModal({
            nom: '',
            prenom: '',
            mail: '',
            tel: '',
            compte: ''
          });
       
        toast.success('Ajout effectué avec succès');
        setIsModalOpen(false);
        setErrorModal({});
      } catch (error) {
        if(error.response && error.response.data.errors){
            const errors = error.response.data.errors;
            setErrorModal(errors);
            // Afficher chaque erreur individuellement dans le toast
            if (typeof errors === 'string') {
              toast.error(errors);
            } else {
              for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                  if (Array.isArray(errors[key])) {
                    errors[key].forEach((errMsg) => {
                      toast.error(errMsg);
                    });
                  } else {
                    toast.error(errors[key]);
                  }
                }
              }
            }
        }
      }
    };

    const UpdateReservation = async (id, status) => {
      try {
        const response = await update(id, { status });
    
        if (response) {
          setOpenAttente(false);
    
          setAllReservations((prevReservations) => {
            const isIdInNewUpdate = response.newupdate.some(
              (res) => res.reservation_id === id
            );
    
            if (!isIdInNewUpdate) {
              // Si `id` n'est pas dans `response.newupdate`, supprimer la réservation avec cet `id`
              const filteredReservations = prevReservations.filter(
                (res) => res.reservation_id !== id
              );
              // Mettre à jour les états
              const newCurrentDate = new Date().toISOString().split("T")[0];
              setFilteredStarting(filteredReservations.filter(
                (res) => res.starting_date === newCurrentDate
              ));
              
              const newFilteredOccuper = filteredReservations.filter(r=>r.status === 2)
              setTables(response.tables);
              setTa(response.tab);
              setOccup(newFilteredOccuper.length)
              setOccupes(newFilteredOccuper)
              setDispos(response.newSeats)

              return filteredReservations;
            } else {
              // Sinon, mettre à jour les réservations avec les nouvelles informations
              const updatedReservations = prevReservations.map((res) => {
                const newRes = response.newupdate.find(
                  (r) => r.reservation_id === res.reservation_id
                );
                return newRes ? { ...res, ...newRes } : res;
              });
    
              // Ajouter les nouvelles réservations qui ne sont pas présentes dans les anciennes
              const newReservations = response.newupdate.filter(
                (newRes) => !prevReservations.some((res) => res.reservation_id === newRes.reservation_id)
              );
    
              const allProcessedReservations = [...updatedReservations, ...newReservations];
    
              // Séparer date et heure
              const processedReservations = allProcessedReservations.map((reserve) => {
                if (reserve.starting_date) {
                  const [date, time] = reserve.starting_date.split(" ");
                  return {
                    ...reserve,
                    starting_date: date,
                    starting_time: time || reserve.starting_time,
                    status: reserve.status,
                  };
                }
                return { ...reserve };
              });
    
              // Mettre à jour les réservations pour la date courante
              const newCurrentDate = new Date().toISOString().split("T")[0];
              const filteredReservations = processedReservations.filter(
                (res) => res.starting_date === newCurrentDate
              );
            
              const filteredUpdateOccuper = processedReservations.filter(res=>res.status === 2);
              const filteredUpdatAttente = processedReservations.filter(res=>res.status === 1);

               // Mettre à jour les états
              setAttente(filteredUpdatAttente.length)
              setAttentes(filteredUpdatAttente)

              setOccup(filteredUpdateOccuper.length)
              setOccupes(filteredUpdateOccuper)
             
              setFilteredStarting(filteredReservations);
              setTables(response.tables);
              setTa(response.tab);
              setDispos(response.newSeats)
    
              return processedReservations;
            }
          });
    
          toast.success("Opération effectuée avec succès");
        }
       
      } catch (error) {
        console.error("Erreur lors de la mise à jour ", error);
      }
    };
    
    const handleAttente = (filteredStarting)=>{
      setSelectedReservation(filteredStarting);
      setOpenAttente(true)
    }

    const handleAttenteClose= ()=>{
      setOpenAttente(false)
    }
    const today = new Date().toISOString().split('T')[0];

    useEffect(()=>{
      const handleAzCustomer = async()=>{
        await axios.get(`${process.env.REACT_APP_BASE_URL}/api/get-az-client`).then((res)=>{
             setAzCustomers(res.data.customers)
        }).catch((err)=>{
          console.log("Erreur lors de l'appel a l'API ",err)
        })
        setEtatForm(false)
        setAzForm(true)
      }
      handleAzCustomer()
    },[])

     

    const [newform,setNewForm] = useState({
      name: "",
    })

    const handleAzFormSubmit = (e)=>{
      e.preventDefault()
      axios.post(`${process.env.REACT_APP_BASE_URL}/api/store-az-client`,newform).then((res)=>{
          if(res.data.status === 200){
            toast.success(res.data.message)
            setIsModalOpen(false)
            setCustormer(res.data.customers)
            setNewForm({
              name:""
            })
          }
      }).catch((err)=>{
        console.log("Erreur lors de l'appel à l'API ",err)
      })
    }

    const handleAzCustomerChange = (event) => {
      const checked = event.target.checked; // Obtenir la valeur directement
      setIsChecked(checked); // Mettre à jour l'état
  
      if (checked) {
          setAzForm(true);
          setEtatForm(false);
      } else {
          setAzForm(false);
          setEtatForm(true);
      }
  };

  const handleAzFormChange = (e)=>{
    const {name,value} = e.target;
   setNewForm((prevazcustomer)=>({
    ...prevazcustomer,
    [name]:value
   }))
  }
useEffect(()=>{
  const updateForTime = ()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/reservation-time`).then((res)=>{
     if(res.data.status === 200)
     {
      console.log("liberer")
      toast.success(res.data.message)
     }
    }).catch((err)=>{
      console.log("Erreur lors de l'appel à l'API ",err)
    })
  }
  updateForTime()
},[])
 

  return (
    
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
         <ToastContainer />
          <div className='flex flex-row bg-[#F7F7F7] h-screen'>
            {/* Section gauche */}
            <div className='w-2/3 barre_scroll'>
              {/* Navbar */}
              <div className='flex s:justify-between s:flex-row xs:flex-col s:h-20 xs:h-32 s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md s:px-6 xs:px-2 z-10 fixed w-2/3'>
                <div className='text-3xl'>
                   <img className="w-40 h-10  object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
                </div>
                <strong><h1 className="text-2xl font-bold">ON-SITE</h1></strong> 
                <div>
                  <input type='search' onChange={handleReservationChange} className='bg-[#EEEEEE] h-10 px-3 outline-none text-base' placeholder='Entrer un mot clé' />
                </div>
              </div>
              <div className='p-6 s:text-2xl xs:text-lg text-[#4E4E4E] mt-20'>Listes des réservations</div>

              <div className='flex s:flex-row xs:flex-col s:justify-center s:items-center s:space-x-6 xs:space-y-6 s:space-y-0 pl-6'>
                <input type='date' className='h-10 w-[220px] px-3 bg-white rounded-md' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <input type='time' className='h-10 w-[100px] px-3 bg-white rounded-md' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
              </div>

              <div className='p-6 mt-7'>
                <div className='flex s:flex-row s:space-x-6 xs:flex-col xs:space-y-6 s:space-y-0'>
                  {/* place reservé */}
                  <div 
                      className={`${bgColorr} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}
                      onClick={()=>onTablerClick()}
                      >
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                      <img src={table} alt='table' />
                    </div>
                    <div className='text-lg'>{tables && tables} {process.env.REACT_APP_RESERVER}</div>
                  </div>
                  {/* place disponible */}
                  <div 
                      className={`${bgColord} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}
                      onClick={()=>onDispoClick()}
                      >
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                      <img src={table} alt='table' />
                    </div>
                    <div className='s:text-lg xs:text-base text-dark'> {dispos && dispos.length} {process.env.REACT_APP_DISPONIBLE}</div>
                  </div>

                  {/* place en attente */}
                  <div 
                      className={`${bgColora} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}
                      onClick={()=>onAttentClick()}
                      >
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                      <img src={table} alt='table' />
                    </div>
                    <div className='s:text-lg xs:text-base text-dark'> {attente && attente} {process.env.REACT_APP_ATTENTE}</div>
                  </div>

                  {/* place en occupée */}
                  <div 
                      className={`${bgColoro} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}
                      onClick={()=>onOccupClick()}
                      >
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                      <img src={table} alt='table' />
                    </div>
                    <div className='s:text-lg xs:text-base text-dark'> {occup && occup} {process.env.REACT_APP_OCCUPE}</div>
                  </div>
                </div>
                {
                  tabler &&   
                  <div className='grid s:grid-cols-2 xs:grid-cols-1 my-20 gap-y-6'>
                      {
                      filteredStarting && filteredStarting.map((reservat, index) => (
                        <div key={index} className='bg-white flex flex-row items-center space-x-6 w-[400px] rounded-md shadow p-3'>
                          <div className='bg-[#EFEFEF] w-14 h-14 flex justify-center items-center rounded-full'>
                            <img src={reserved} alt='table' />
                          </div>
                          <div className='w-[180px]'>
                            <div className='font-semibold'>{reservat.LastName ? reservat.LastName : ''}  {reservat.Names ? reservat.Names : ''}</div>
                            
                            <div className='text-sm text-[#616161]'>
                                {reservat.status === 1 &&  (
                                  
                                  'Heure d\'arrivée ' + (reservat.starting_time ? reservat.starting_time : '8h08') 
                                )}
                                {reservat.status === 2 && (
                                  'Occupée à ' + (reservat.ending_date ? reservat.ending_date.split(' ')[1] : '8h08')
                                )}
                                {reservat.status === 3 && (
                                  'Heure d\'arrivée ' + (reservat.starting_time ? reservat.starting_time : '8h08')
                                )}
                            </div>
                            
                            <div className='text-sm text-[#616161] italic font-semibold'> {reservat.seat_id ? 'Table '+ reservat.seat_id : 'Non assigné'} </div>
                          </div>
                          <div>
                              {reservat.status === 1 && (
                                <div 
                                  onClick={()=>handleAttente(reservat)}
                                  className='cursor-pointer bg-[#616161]  text-white w-[90px] text-center py-1 rounded'>
                                  Assigner
                                </div>
                              )}
                              {reservat.status === 2 && (
                                <div className="d-flex">
                                 <div
                                    className='flex justify-center items-center cursor-pointer bg-[#28a745] text-white w-[50px] text-center py-1 rounded mb-3'>
                                    <Link to={`/?q=${encodeURIComponent(reservat.customers)}`}>
                                      <FaShoppingCart size={20} />
                                    </Link>
                                  </div>
                                  <div
                                    onClick={() => handleAttente(reservat)}
                                    className='flex justify-center items-center cursor-pointer bg-[#D80E0E] text-white w-[50px] text-center py-1 rounded mb-3'>
                                     <FaBan size={20} />
                                  </div>
                                </div>
                              )}
                              
                              {reservat.status === 3 && (
                                <div className='bg-[#28A745] text-white w-[90px] text-center py-1 rounded'>Occupée</div>
                              )}

                      {openAttent &&  selectedReservation && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                  <div className="bg-white p-8 rounded shadow-lg">
                                    <h2 className="text-xl mb-4"> 
                                      {selectedReservation.status === 1 && 'Voulez-vous assigner cette table à ' + selectedReservation.LastName + ' ' + selectedReservation.Names + '?'} 
                                      {selectedReservation.status === 2 && 'Voulez-vous terminer la commande de ' + selectedReservation.LastName + ' ' + selectedReservation.Names + '?'} 
                                    </h2>
                                    <div className="flex justify-end mt-4 space-x-4">
                                      <button
                                        className="bg-[#28a745] text-white px-4 py-2 rounded"
                                        onClick={() => UpdateReservation(selectedReservation.reservation_id, selectedReservation.status)}
                                      >
                                        Comfirmer
                                      </button>
                                      <button
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                        onClick={handleAttenteClose}
                                      >
                                        Annuler
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      ))
                      }
                      {
                        filteredStarting.length === 0 &&
                        <div className="flex items-center justify-center" >
                            <div className="bg-red-600 p-4 text-white">Aucune table reservée pour ce jour </div>
                        </div>
                      }
                   </div>
                }
                {
                  dispo && 
                  <div className='grid s:grid-cols-2 xs:grid-cols-1 my-20 gap-y-6'>
                        {
                         dispos && dispos.map((reservat, index) => (
                          <div key={index} className='bg-white flex flex-row items-center space-x-6 w-[400px] rounded-md shadow p-3'>
                            <div className='bg-[#EFEFEF] w-14 h-14 flex justify-center items-center rounded-full'>
                              <img src={reserved} alt='table' />
                            </div>
                            <div className='w-[180px]'>
                              
                              <div className='text-sm text-[#616161] italic font-semibold'> {reservat.seat_id ? 'Table '+ reservat.seat_id : 'Non assigné'} </div>
                            </div>
                            <div>
                                  <div 
                                    className='cursor-pointer bg-[#209313]  text-white w-[90px] text-center py-1 rounded'>
                                      Reservé
                                  </div>
                                  {openAttent &&  selectedReservation && (
                                          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                            <div className="bg-white p-8 rounded shadow-lg">
                                              <h2 className="text-xl mb-4"> 
                                                {selectedReservation.status === 1 && 'Voulez-vous rendre la table occupée ?'} 
                                                {selectedReservation.status === 2 && 'Voulez-vous terminer la commande ?'} 
                                              </h2>
                                              <div className="flex justify-end mt-4 space-x-4">
                                                <button
                                                  className="bg-[#28a745] text-white px-4 py-2 rounded"
                                                  onClick={() => UpdateReservation(selectedReservation.reservation_id, selectedReservation.status)}
                                                >
                                                  Valider
                                                </button>
                                                <button
                                                  className="bg-gray-500 text-white px-4 py-2 rounded"
                                                  onClick={handleAttenteClose}
                                                >
                                                  Annuler
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                    )}
                            </div>
                          </div>
                        ))
                        }
                        {
                          dispo.length === 0 && 
                          <div className="flex items-center justify-center">
                               <div className="bg-red-600 p-4 text-white">Aucune table disponible pour ce jour </div>
                          </div>
                        }
                  </div>
                }
                {
                  attent &&
                  <div className='grid s:grid-cols-2 xs:grid-cols-1 my-20 gap-y-6'>
                      {
                        attentes && attentes.map((reservat, index) => (
                        <div key={index} className='bg-white flex flex-row items-center space-x-6 w-[400px] rounded-md shadow p-3'>
                          <div className='bg-[#EFEFEF] w-14 h-14 flex justify-center items-center rounded-full'>
                            <img src={reserved} alt='table' />
                          </div>
                          <div className='w-[180px]'>
                            <div className='font-semibold'>{reservat.LastName ? reservat.LastName : ''}  {reservat.Names ? reservat.Names : ''}</div>
                            
                            <div className='text-sm text-[#616161]'>
                                {reservat.status === 1 &&  (
                                  
                                  'Heure d\'arrivée ' + (reservat.starting_time ? reservat.starting_time : '8h08') 
                                )}
                                {reservat.status === 2 && (
                                  'Occupée à ' + (reservat.ending_date ? reservat.ending_date.split(' ')[1] : '8h08')
                                )}
                                {reservat.status === 3 && (
                                  'Heure d\'arrivée ' + (reservat.starting_time ? reservat.starting_time : '8h08')
                                )}
                            </div>
                            
                            <div className='text-sm text-[#616161] italic font-semibold'> {reservat.seat_id ? 'Table '+ reservat.seat_id : 'Non assigné'} </div>
                          </div>
                          <div>
                              {reservat.status === 1 && (
                                <div 
                                  onClick={()=>handleAttente(reservat)}
                                  className='cursor-pointer bg-[#616161]  text-white w-[90px] text-center py-1 rounded'>
                                    Assigné
                                </div>
                              )}
                              {reservat.status === 2 && (
                                <div className="d-flex">
                                  <div
                                    className='cursor-pointer bg-[#28a745] text-white w-[90px] text-center py-1 rounded mb-3'>
                                    <Link to={`/?q=${encodeURIComponent(reservat.customers)}`}><FaPlay /></Link>
                                  </div>
                                  <div
                                    onClick={() => handleAttente(reservat)}
                                    className='cursor-pointer bg-[#D80E0E] text-white w-[90px] text-center py-1 rounded mb-3'>
                                    Occupé
                                  </div>
                                </div>
                              )}
                              
                              {reservat.status === 3 && (
                                <div className='bg-[#28A745] text-white w-[90px] text-center py-1 rounded'>Occupée</div>
                              )}

                      {openAttent &&  selectedReservation && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                  <div className="bg-white p-8 rounded shadow-lg">
                                    <h2 className="text-xl mb-4"> 
                                      {selectedReservation.status === 1 && 'Voulez-vous assigner cette table à ' + selectedReservation.LastName + ' ' +selectedReservation.Names + '?'} 
                                      {selectedReservation.status === 2 && 'Voulez-vous terminer la commande de '  + selectedReservation.LastName + ' ' +selectedReservation.Names + '?'} 
                                    </h2>
                                    <div className="flex justify-end mt-4 space-x-4">
                                      <button
                                        className="bg-[#28a745] text-white px-4 py-2 rounded"
                                        onClick={() => UpdateReservation(selectedReservation.reservation_id, selectedReservation.status)}
                                      >
                                        Valider
                                      </button>
                                      <button
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                        onClick={handleAttenteClose}
                                      >
                                        Annuler
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      ))
                      }
                      {attentes.length ===0 && 
                        <div className="flex items-center justify-center">
                             <div className="bg-red-600 p-4 text-white">Aucune table entente pour ce jour </div>
                        </div>
                      }
                  </div>  
                }
                {
                    occupe && 
                    <div className='grid s:grid-cols-2 xs:grid-cols-1 my-20 gap-y-6'>
                        {
                         occupes && occupes.map((reservat, index) => (
                          <div key={index} className='bg-white flex flex-row items-center space-x-6 w-[400px] rounded-md shadow p-3'>
                            <div className='bg-[#EFEFEF] w-14 h-14 flex justify-center items-center rounded-full'>
                              <img src={reserved} alt='table' />
                            </div>
                            <div className='w-[180px]'>
                              <div className='font-semibold'>{reservat.LastName ? reservat.LastName : ''}  {reservat.Names ? reservat.Names : ''}</div>
                              
                              <div className='text-sm text-[#616161]'>
                                  {reservat.status === 1 &&  (
                                    
                                    'Heure d\'arrivée ' + (reservat.starting_time ? reservat.starting_time : '8h08') 
                                  )}
                                  {reservat.status === 2 && (
                                    'Occupée à ' + (reservat.ending_date ? reservat.ending_date.split(' ')[1] : '8h08')
                                  )}
                                  {reservat.status === 3 && (
                                    'Heure d\'arrivée ' + (reservat.starting_time ? reservat.starting_time : '8h08')
                                  )}
                              </div>
                              
                              <div className='text-sm text-[#616161] italic font-semibold'> {reservat.seat_id ? 'Table '+ reservat.seat_id : 'Non assigné'} </div>
                            </div>
                            <div>
                              <div className="d-flex">
                              <div
                                    className='flex justify-center items-center cursor-pointer bg-[#28a745] text-white w-[50px] text-center py-1 rounded mb-3'>
                                    <Link to={`/?q=${encodeURIComponent(reservat.customers)}`}>
                                      <FaShoppingCart size={20} />
                                    </Link>
                                  </div>
                                  <div
                                    onClick={() => handleAttente(reservat)}
                                    className='flex justify-center items-center cursor-pointer bg-[#D80E0E] text-white w-[50px] text-center py-1 rounded mb-3'>
                                     <FaBan size={20} />
                                  </div>
                              </div>
                              {openAttent &&  selectedReservation && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                  <div className="bg-white p-8 rounded shadow-lg">
                                    <h2 className="text-xl mb-4"> 
                                      {selectedReservation.status === 1 && 'Voulez-vous rendre la table assigner cette table à '  + selectedReservation.LastName + ' ' +selectedReservation.Names + '?'} 
                                      {selectedReservation.status === 2 && 'Voulez-vous terminer la commande de '  + selectedReservation.LastName + ' ' +selectedReservation.Names + '?'} 
                                    </h2>
                                    <div className="flex justify-end mt-4 space-x-4">
                                      <button
                                        className="bg-[#28a745] text-white px-4 py-2 rounded"
                                        onClick={() => UpdateReservation(selectedReservation.reservation_id, selectedReservation.status)}
                                      >
                                        Valider
                                      </button>
                                      <button
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                        onClick={handleAttenteClose}
                                      >
                                        Annuler
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                        }
                        {
                          occupes.length === 0 && 
                          <div className="flex items-center justify-center">
                               <div className="bg-red-600 p-4 text-white">Aucune table occupée pour ce jour </div>
                          </div>
                        }
                    </div> 
                }
              </div>
            </div>
            <div className='w-1/3 bg-white barre_scroll'>
              <Header />
              {dispos.length >0  && (
                <>
                  <div className='md:mt-24 xs:mt-80 pl-6 xs:text-base md:text-xl'>Formulaire de réservation</div>
                      <form onSubmit={handlerSubmit}>
                        <div className='p-6 flex flex-col space-y-5'>
                          {/* Personnes */}
                          <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                            <FaUser className='text-xl text-[#616161]' />
                            <select
                              className='w-[300px] px-2 h-10 outline-none'
                              name="person"
                              value={formDatas.person}
                              onChange={handleChange}
                            >
                              <option value="1">1 Personne</option>
                              <option value="2">2 Personnes</option>
                              <option value="3">3 Personnes</option>
                              <option value="4">4 Personnes</option>
                              <option value="5">5 Personnes</option>
                              <option value="6">6 Personnes</option>
                              <option value="plus">Plus</option>
                            </select>
                          </div>
                          {showInput && (
                            <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                              <FaUser className='text-xl text-[#616161]' />
                              <input
                                type='number'
                                className='w-[300px] px-2 h-10 outline-none'
                                placeholder="Entrer le nombre de personnes"
                                name="persons"
                                value={formDatas.persons}
                                onChange={handleInputPersonChange}
                              />
                            </div>
                          )}
                          {errors.person && <p className="text-red-500 text-xs mt-1">{errors.person}</p>}

                          {/* Sélection du nom */}
                          <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                            <FaUser className='text-xl text-[#616161]' />
                            <select
                              className='w-[300px] px-2 h-10 outline-none'
                              name="noms"
                              value={formDatas.noms}
                              onChange={handleChange}
                            >
                              <option>choisie le client</option>
                              {custormer.map((custorm, index) => (
                                <option key={index} value={custorm.Customers_Numbers}>{custorm.Names} {custorm.LastName}</option>
                              ))}
                            </select>
                            <p onClick={handeClickP}>
                              <svg className="w-[26px]  text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clipRule="evenodd" />
                              </svg>
                            </p>
                          </div>
                          <div>{errors.noms && <p className="text-red-500 text-xs">{errors.noms}</p>}</div>
                          {/* Date */}
                          <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                            <BsCalendarDate className='text-xl text-[#616161]' />
                            <input
                              type='date'
                              className='w-[300px] px-2 h-10 outline-none'
                              name="datedeb"
                              value={formDatas.datedeb}
                              onChange={handleChange}
                              min={today}
                            />
                          </div>
                          {errors.datedeb && <p className="text-red-500 text-xs mt-1">{errors.datedeb}</p>}

                          {/* Heure */}
                          <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                            <MdOutlineAccessTime className='text-xl text-[#616161]' />
                            <input
                              type='time'
                              className='w-[300px] px-2 h-10 outline-none'
                              name="heuredeb"
                              value={formDatas.heuredeb}
                              onChange={handleChange}
                            />
                          </div>
                          {errors.heuredeb && <p className="text-red-500 text-xs mt-1">{errors.heuredeb}</p>}

                          {/* Message */}
                          <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                            <textarea
                              rows='4'
                              className='w-[300px] p-2 h-32 outline-none'
                              name="message"
                              value={formDatas.message}
                              placeholder='Note'
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}

                          <button type="submit" className='bg-[#D19E06] text-white w-[340px] h-10 rounded-md'>
                            Valider la réservation
                          </button>
                        </div>
                      </form>
                </>
              )}
                      
             {isModalOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                  <div className="bg-white p-8 rounded shadow-lg">
                     
                    { 
                      <>
                          <p>
                            <input 
                                   onChange={handleAzCustomerChange}  
                                   className="ml-2 mt-2" type="checkbox" 
                                   checked={isChecked}
                                   />
                            <label  htmlFor="compte" className="flex-1 ml-2"><strong>Ce client a un compte AZ</strong></label>
                          </p>
                          {
                            etatForm && 
                               <div>
                                <h2 className="text-xl mb-4">Veuillez remplire le formulaire</h2>
                                  <form onSubmit={handleModalFormSubmit}>
                                        <div className="mt-2 mb-2 d-flex ml-2">
                                        
                                            <p>
                                              Type:
                                                <input onChange={handelFormModalChange} onClick={handlEntreChange}  className="ml-2 mt-2" type="radio" name="compte" value="webcompany" id="compte" />
                                                <label  htmlFor="compte" className="flex-1 ml-2">Entreprise</label>
                                                <input onChange={handelFormModalChange} onClick={handlePartChange}  className="mt-2 ml-3"  type="radio" name="compte" value="webbyyer" id="compte"/>
                                                <label  htmlFor="compte" className="flex-1 ml-2">Particulier</label>
                                            </p>
                                            {errorModal.compte && <p className="text-red-500 text-xs mt-1">{errorModal.compte}</p>}
                                        </div>
                                        <div >
                                          {part && (
                                            <>
                                              <div className='mb-4 border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                                                <input
                                                  type='text'
                                                  className='w-[340px] px-2 h-10 outline-none'
                                                  placeholder='Nom'
                                                  name="nom"
                                                  value={formModal.nom}
                                                  onChange={handelFormModalChange}
                                                />
                                              </div>
                                              {errorModal.nom && <p className="text-red-500 text-xs mt-1">{errorModal.nom}</p>}

                                              <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                                                    <input
                                                      type='text'
                                                      className='w-[140px] px-2 h-10 outline-none'
                                                      placeholder='Prénom'
                                                      name="prenom"
                                                      value={formModal.prenom}
                                                      onChange={handelFormModalChange}
                                                    />
                                              </div>
                                              {errorModal.prenom && <p className="text-red-500 text-xs mt-1">{errorModal.prenom}</p>}
                                            </>
                                          )}
                                        </div>
                                        {/* nom */}
                                        {entre && (
                                            <>
                                              <div className='border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2'>
                                                <input
                                                  type='text'
                                                  className='w-[340px] px-2 h-10 outline-none'
                                                  placeholder="Nom de l'entreprise"
                                                  name="nom"
                                                  value={formModal.nom}
                                                  onChange={handelFormModalChange}
                                                />
                                            </div>
                                              {errorModal.nom && <p className="text-red-500 text-xs mt-1">{errorModal.nom}</p>}
                                            </>
                                            
                                          )
                                        }
                                      <div className="mb-3 border-2 mt-3 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                                          <input
                                            type="text"
                                            className="w-[300px] px-2 h-10 outline-none"
                                            placeholder="Adresse e-mail"
                                            name="mail"
                                            value={formModal.mail}
                                            onChange={handelFormModalChange}
                                          />
                                      </div>
                                      {errorModal.mail && <p className="text-red-500 text-xs mt-1">{errorModal.mail}</p>}
                                      <div className="mb-3 border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                                          <input
                                            type="text"
                                            className="w-[300px] px-2 h-10 outline-none"
                                            placeholder="Téléphone"
                                            name="tel"
                                            value={formModal.tel}
                                            onChange={handelFormModalChange}
                                          />
                                      </div>
                                      {errorModal.tel && <p className="text-red-500 text-xs mt-1">{errorModal.tel}</p>}
                                      <button type="submit" className='bg-[#D19E06] text-white w-[340px] h-10 rounded-md'>
                                          Valider 
                                      </button>
                                  </form>
                              </div>
                          }

                        {azForm && 
                          <div>
                              <form onSubmit={handleAzFormSubmit}>     
                                  <div className="mb-3 border-2 border-[#C2C2C2] w-[340px] rounded-md flex items-center px-3 space-x-2">
                                  <select
                                            className='w-[300px] px-2 h-10 outline-none'
                                            name="name"
                                            value={newform.name}
                                            onChange={handleAzFormChange}
                                            
                                        >
                                            <option>Veuillez choisir le client</option>
                                            {azCustomers.map((custome, index) => (
                                                <option key={index} value={custome.Customers_Numbers}>
                                                    {custome.LastName} {custome.Names}
                                                </option>
                                            ))}
                                  </select>

                                  </div>
                                  <button type="submit" className='bg-[#D19E06] text-white w-[340px] h-10 rounded-md'>
                                      Valider 
                                  </button>
                              </form>
                            </div>
                         }
                        
                             
                      </>
                      
                    }
                      
                         
                    <div className="flex justify-end mt-4 space-x-4">
                      <button
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={handleCloseModal}
                      >
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              )} 
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Reservation